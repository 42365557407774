import { types } from "mobx-state-tree";

export const model = types
    .model({
        apiToken: types.maybe(types.string),
    })
    .actions((self) => ({
        setApiToken: (token: string) => {
            self.apiToken = token;
        },
        removeApiToken: (): void => {
            self.apiToken = undefined;
        },
        onLoad: () => {},
    }));

export const mdxEditorStore = model.create({});

export default mdxEditorStore;
