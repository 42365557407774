import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import StandaloneAppRenderer from "@mittwald/flow-renderer/dist/components/StandaloneAppRenderer/StandaloneAppRenderer";
import React from "react";
import { PageSkeleton } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/PageSkeleton";
import { LoadingView } from "@mittwald/flow-components/dist/components/LoadingView";
import { useCheckGitlabApiTokenAccess } from "../hooks/useCheckGitlabApiTokenAccess";
import { faKey } from "@fortawesome/pro-regular-svg-icons/faKey";
import { lazy } from "@mittwald/flow-lib/dist/lib/lazy";

export const mdxEditorPageTree = PageTree.build(StandaloneAppRenderer, {
    "/": {
        viewSlots: {
            main: {
                content: lazy(() => import("./EditorPage")),
                noAccess: lazy(() => import("./NoAccessPage")),
                loading: <LoadingView />,
            },
        },
        linkId: "app",
        hooks: {
            useCheckAccess: useCheckGitlabApiTokenAccess,
        },
    },
    "/set-api-token": {
        viewSlots: {
            main: {
                content: lazy(() => import("./SetApiTokenPage")),
                noAccess: lazy(() => import("./SetApiTokenPage")),
                loading: <PageSkeleton type="modal" />,
            },
        },
        icon: faKey,
        isOverlay: true,
        linkId: "setApiToken",
    },
});
