import components from "@mittwald/flow-components/dist/i18n/de";
import renderer from "@mittwald/flow-renderer/dist/i18n/de";
import docusaurus from "@mittwald/flow-docusaurus-theme/dist/i18n/de";

export default {
    ...components,
    ...renderer,
    ...docusaurus,
    ...require("./de.json"),
};
