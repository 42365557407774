import { UseCheckAccessHook } from "@mittwald/flow-lib/dist/access/types";
import { useGetCurrentUser } from "../api/Gitlab";

export const useCheckGitlabApiTokenAccess: UseCheckAccessHook = () => {
    const response = useGetCurrentUser({
        header: {
            Accept: "application/json",
        },
    });

    if (response.state === "loading") {
        return { isLoading: true };
    }

    if (response.state === "ok") {
        return { isLoading: false, result: true };
    }

    if (response.state === "noAccess" || response.state === "unauthorized") {
        return { isLoading: false, result: false };
    }

    throw response;
};
