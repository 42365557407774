import { AppBootstrap } from "@mittwald/flow-components/dist/components/AppBootstrap";
import React, { FC } from "react";
import "./api/clients/gitlab";
import { config } from "./config";
import defaultLanguage from "./i18n/de";
import { mdxEditorPageTree } from "./pages/pageTree";
import mdxEditorStore from "./store/mdxEditor";

const App: FC = () => (
    <AppBootstrap
        defaultLanguage={defaultLanguage}
        environment={config.environment}
        importLanguageFile={(language) => import(`./i18n/${language}`)}
        name="mdx-editor"
        pageTree={mdxEditorPageTree}
        persistedStores={[["mdxEditor", mdxEditorStore]]}
        sentryDsn={config.sentryDsn}
        version={config.version}
    />
);

export default App;
