import { KyClient } from "@mittwald/api-client/dist/http/KyClient";
import { autorun } from "mobx";
import { config } from "../../config";
import mdxEditorStore from "../../store/mdxEditor";
import { GitlabApiClient } from "../Gitlab";

const httpClient = new KyClient({
    ky: {
        prefixUrl: config.gitlabApiUri,
    },
});

autorun(() => {
    httpClient.setDefaultHeaders({
        Authorization: `Bearer ${mdxEditorStore.apiToken}`,
    });
});

export const gitlabApiClient = new GitlabApiClient(httpClient);
GitlabApiClient.setInstance(gitlabApiClient);

export default gitlabApiClient;
