import { asset } from "@mittwald/flow-styles/dist/tokens/ts/assets";
import makeConfig from "@mittwald/flow-lib/dist/dev/makeConfig";

export const config = makeConfig({
    sentryDsn: {
        start: "${REACT_APP_SENTRY_DSN}",
        defaults: undefined,
    },
    appIcon: {
        build: "${REACT_APP_APP_ICON}",
        defaults: asset.icon.brand.appIcon,
    },
    environment: {
        defaults: {
            local: "local",
            dev: "development",
            prod: "production",
        },
    },
    gitlabApiUri: {
        start: "${REACT_APP_GITLAB_API_URI}",
        defaults: {
            dev: "https://gitlab.mittwald.it/api/v4",
            prod: "https://gitlab.mittwald.it/api/v4",
        },
    },
    cookiePrefix: {
        start: "${REACT_APP_COOKIE_PREFIX}",
        defaults: "mw-dev",
    },
    version: {
        build: "${REACT_APP_VERSION}",
        defaults: "local-dev",
    },
});
